/* You can add global styles to this file, and also import other style files */
/*
deeppurple-amber.css
indigo-pink.css
pink-bluegrey.css
purple-green.css
*/
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
    font-family: 'Roboto', sans-serif;    
    /* font-size: 13px; */
}